(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  "mobile": {
    "landscape": 812,
    "portrait": 700,
    "small": 480
  },
  "tablet": {
    "landscape": 1024,
    "portrait": 768
  },
  "notebook": {
    "small": 1280,
    "normal": 1500
  }
};

},{}],2:[function(require,module,exports){
'use strict';

require('./modules/lazyload.js');

require('./modules/modals.js');

require('./modules/forms.js');

require('./modules/masks.js');

require('./modules/spinner.js');

require('./modules/scroll.js');

require('./modules/sliders.js');

require('./modules/menus.js');

require('./modules/tabs.js');

require('./modules/spoilers.js');

require('./modules/fixed-aside.js');

require('./modules/video.js');

require('./modules/search.js');

require('./modules/models.js');

require('./modules/vuejs/card/card-app.js');

require('./modules/vuejs/order/order-app.js');

},{"./modules/fixed-aside.js":3,"./modules/forms.js":4,"./modules/lazyload.js":7,"./modules/masks.js":8,"./modules/menus.js":9,"./modules/modals.js":10,"./modules/models.js":11,"./modules/scroll.js":12,"./modules/search.js":13,"./modules/sliders.js":14,"./modules/spinner.js":15,"./modules/spoilers.js":16,"./modules/tabs.js":17,"./modules/video.js":18,"./modules/vuejs/card/card-app.js":19,"./modules/vuejs/order/order-app.js":25}],3:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var _helpers = require('./helpers.js');

var $fixedAside = $('.js-fixed-aside');

if ($fixedAside.length && _helpers.WINDOW_WIDTH > _globals.LANDSCAPE_MOBILE_WIDTH) {
  var PANEL_OFFSET_TOP = 30 + $('.header').height();
  var PANEL_OFFSET_BOTTOM = 0;
  var $container = $fixedAside.closest('.js-layout-root');
  var asideHeight = $fixedAside.outerHeight() + PANEL_OFFSET_BOTTOM;
  var asidePosition = $fixedAside.offset().top;
  var bottomLimit = $container.offset().top + $container.height();
  var maxOffset = bottomLimit - asideHeight;

  _globals.$WINDOW.on('resize basketHeightChange', function () {
    asideHeight = $fixedAside.outerHeight() + PANEL_OFFSET_BOTTOM;
    asidePosition = $fixedAside.offset().top;
    bottomLimit = $container.offset().top + $container.height();
    maxOffset = bottomLimit - asideHeight;
  });

  _globals.$WINDOW.on('scroll.order-page-scroll', function () {
    var scrollTop = _globals.$WINDOW.scrollTop();
    var offset = scrollTop - asidePosition + PANEL_OFFSET_TOP;

    if (offset < 0) {
      $fixedAside.css('top', 0);
    } else if (offset >= 0) {
      if (scrollTop < maxOffset - PANEL_OFFSET_TOP) {
        $fixedAside.css('top', offset);
      } else {
        $fixedAside.css('top', maxOffset - asidePosition);
      }
    }
  });
}

_globals.$WINDOW.on('load mousewheel scroll', function () {
  var $window = $(window);
  var $positionForStart = $window.height();
  var $header = $('.header');

  if ($window.scrollTop() >= $header.innerHeight()) {
    $header.css({
      transform: 'translateY(-100%)'
    });
  } else {
    $header.css({
      transform: 'translateY(0)'
    });
  }

  if ($window.width() <= 1090) {
    $positionForStart /= 4;
  }

  if ($window.scrollTop() >= $positionForStart / 3) {
    $header.addClass('fixed--header');
  } else {
    $header.removeClass('fixed--header');
  }
});

},{"./globals.js":5,"./helpers.js":6}],4:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

// Selectric
// ---------

_globals.$DOCUMENT.on('initSelectric yiiListViewUpdated', function () {
  $('select:not(.no-default-selectric)').selectric({
    disableOnMobile: false,
    nativeOnMobile: true
  });
}).trigger('initSelectric');

// Checkboxes
// ----------

/* global autosize */

_globals.$BODY.on('change initCheckboxes', '.js-checkbox input', function (event) {
  var $inp = $(event.target);
  var $label = $inp.closest('.js-checkbox');

  if ($inp.prop('checked')) {
    $label.addClass('is-checked');
  } else {
    $label.removeClass('is-checked');
  }
});
$('.js-checkbox input').trigger('initCheckboxes');

// Radio buttons
// -------------

_globals.$BODY.on('change initRadio', '.js-radio input', function (event) {
  var $inp = $(event.target);
  var $group = $('[name="' + $inp.attr('name') + '"]');
  var $labels = $group.closest('.js-radio');
  var $selectedItem = $labels.find('input').filter(':checked').closest('.js-radio');

  $labels.removeClass('is-checked');
  $selectedItem.addClass('is-checked');
});
$('.js-radio input').trigger('initRadio');

// Textarea autosize
// -----------------

$(function () {
  autosize($('textarea'));
});

// Touched inputs state
// --------------------

$('div.inp').find('.inp, .textarea').on('focus', function focusHandler() {
  $(this).closest('div.inp').addClass('is-touched');
}).on('blur', function blurHandler() {
  var $this = $(this);
  var value = $this.val();

  if (value !== '' && value != null && value !== '+7 (___) ___-__-__') return;

  $this.closest('div.inp').removeClass('is-touched');
}).on('inputs-init', function inputsInit() {
  var $this = $(this);
  var value = $this.val();

  if (value !== '' && value != null && value !== '+7 (___) ___-__-__') {
    $this.closest('div.inp').addClass('is-touched');
  }
}).trigger('inputs-init');

// Fileinputs
// ----------

_globals.$BODY.on('change', '.js-fileinput input', function changeHandler(event) {
  $(event.target).closest('.js-fileinput').parent().find('.js-fileinput-filename').text($(this).val().substring(12));
});

$(function () {
  $('.js-novue-datepicker').each(function addDatepicker() {
    var $input = $(this);
    $input.datepicker({
      // minDate: 0,
      dateFormat: 'dd-mm-yy'
    });
  });
});

},{"./globals.js":5}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOSRUS_DEFAULTS = exports.$BODY = exports.$HTML = exports.$DOCUMENT = exports.$WINDOW = exports.HEADER_HEIGHT = exports.NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = exports.TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = exports.MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = exports.IS_TOUCH_DEVICE = exports.IS_IE = exports.IS_IOS = exports.IS_DESKTOP = exports.IS_MOBILE = undefined;

var _mediaQueriesConfigJson = require('../../media-queries-config.json.js');

var _mediaQueriesConfigJson2 = _interopRequireDefault(_mediaQueriesConfigJson);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Const
// -----

var IS_MOBILE = exports.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null;
var IS_DESKTOP = exports.IS_DESKTOP = !IS_MOBILE;
var IS_IOS = exports.IS_IOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g);
var IS_IE = exports.IS_IE = navigator.appVersion.indexOf('MSIE') !== -1 || navigator.userAgent.match(/Trident.*rv[ :]*11\./);
var IS_TOUCH_DEVICE = exports.IS_TOUCH_DEVICE = 'ontouchstart' in document;

var HTML = document.documentElement;

HTML.classList.remove('no-js');

if (IS_MOBILE) HTML.classList.add('is-mobile');
if (IS_DESKTOP) HTML.classList.add('is-desktop');
if (IS_IOS) HTML.classList.add('is-ios');
if (IS_IE) HTML.classList.add('is-ie');
if (IS_TOUCH_DEVICE) HTML.classList.add('is-touch-device');

var SMALL_MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.small;
var MOBILE_WIDTH = exports.MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.portrait;
var LANDSCAPE_MOBILE_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.landscape;
var PORTRAIT_TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.portrait;
var TABLET_WIDTH = exports.TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.landscape;
var SMALL_NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.small;
var NOTEBOOK_WIDTH = exports.NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.normal;

var HEADER_HEIGHT = exports.HEADER_HEIGHT = $('.header').height();

// Selectors
// ---------

var $WINDOW = exports.$WINDOW = $(window);
var $DOCUMENT = exports.$DOCUMENT = $(document);
var $HTML = exports.$HTML = $(document.documentElement);
var $BODY = exports.$BODY = $(document.body);

// Tosrus default settings
// -----------------------

var TOSRUS_DEFAULTS = exports.TOSRUS_DEFAULTS = {
  buttons: {
    next: true,
    prev: true
  },
  keys: {
    prev: 37,
    next: 39,
    close: 27
  },
  wrapper: {
    onClick: 'close'
  }
};

},{"../../media-queries-config.json.js":1}],6:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IS_SMALL_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = exports.IS_TABLET_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = exports.IS_DESKTOP_WIDTH = exports.WINDOW_HEIGHT = exports.WINDOW_WIDTH = undefined;
exports.status = status;

var _globals = require('./globals.js');

/* eslint-disable import/no-mutable-exports */
var WINDOW_WIDTH = exports.WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
var WINDOW_HEIGHT = exports.WINDOW_HEIGHT = _globals.$WINDOW.height();
/* eslint-enable import/no-mutable-exports */
_globals.$WINDOW.resize(function () {
  exports.WINDOW_WIDTH = WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
  exports.WINDOW_HEIGHT = WINDOW_HEIGHT = _globals.$WINDOW.height();
});

var IS_DESKTOP_WIDTH = exports.IS_DESKTOP_WIDTH = function IS_DESKTOP_WIDTH() {
  return WINDOW_WIDTH > _globals.NOTEBOOK_WIDTH;
};
var IS_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = function IS_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= _globals.NOTEBOOK_WIDTH;
};
var IS_SMALL_NOTEBOOK_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = function IS_SMALL_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.TABLET_WIDTH && WINDOW_WIDTH <= _globals.SMALL_NOTEBOOK_WIDTH;
};
var IS_TABLET_WIDTH = exports.IS_TABLET_WIDTH = function IS_TABLET_WIDTH() {
  return WINDOW_WIDTH >= _globals.PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH <= _globals.TABLET_WIDTH;
};
var IS_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = function IS_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.MOBILE_WIDTH;
};
var IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = function IS_LANDSCAPE_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.LANDSCAPE_MOBILE_WIDTH;
};
var IS_SMALL_MOBILE_WIDTH = exports.IS_SMALL_MOBILE_WIDTH = function IS_SMALL_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.SMALL_MOBILE_WIDTH;
};

// Response status
function status(response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

},{"./globals.js":5}],7:[function(require,module,exports){
'use strict';

(function () {

  var lazyloadImages = document.querySelectorAll('.js-lazy-pic');
  var imageIntersectionObserver = void 0;
  var completed = false;
  var lazyloadThrottleTimeout = void 0;

  document.addEventListener('DOMContentLoaded', function () {
    initLazyLoad();
    addImagesListUpdateHandlers();
  });

  function initLazyLoad() {
    if (supportsIntersectionObserver()) return observeImages();
    return observerFallback();
  }

  function supportsIntersectionObserver() {
    return 'IntersectionObserver' in window;
  }

  function observeImages() {
    createObserver();
    lazyloadImages.forEach(function (image) {
      imageIntersectionObserver.observe(image);
    });
  }

  function createObserver() {
    imageIntersectionObserver = new IntersectionObserver(function (entries) {
      processImages(entries);
    }, {
      rootMargin: '200px'
    });
  }

  function processImages(images) {
    images.forEach(function (item) {
      if (item.isIntersecting) {
        changeSrc(item.target);
        imageIntersectionObserver.unobserve(item.target);
      }
    });
  }

  function changeSrc(image) {
    image.src = image.dataset.src;
    image.classList.remove('js-lazy-pic');
  }

  function observerFallback() {
    document.addEventListener('scroll', lazyload);
    window.addEventListener('resize', lazyload);
    window.addEventListener('orientationChange', lazyload);
  }

  function lazyload() {
    lazyloadImages = document.querySelectorAll('.js-lazy-pic');

    if (lazyloadThrottleTimeout) {
      clearTimeout(lazyloadThrottleTimeout);
    }

    lazyloadThrottleTimeout = setTimeout(function () {
      lazyloadImages.forEach(function (image) {
        if (imageIsReached(image)) {
          changeSrc(image);
        }
      });
      if (noMoreImagesLeft()) {
        removeLazyLoadListeners();
        completed = true;
      }
    }, 20);
  }

  function imageIsReached(image) {
    var scrollTop = window.pageYOffset;
    return image.offsetTop < window.innerHeight + scrollTop;
  }

  function noMoreImagesLeft() {
    return lazyloadImages.length === 0;
  }

  function addImagesListUpdateHandlers() {
    $(document).on('yiiListViewUpdated', function () {
      addImagesToObserver();
    });
    $('body').on('iasRender', function () {
      addImagesToObserver();
    });
  }

  function addImagesToObserver() {
    lazyloadImages = document.querySelectorAll('.js-lazy-pic');
    if (supportsIntersectionObserver()) {
      lazyloadImages.forEach(function (image) {
        imageIntersectionObserver.observe(image);
      });
    } else if (completed) {
      observerFallback();
    }
  }

  function removeLazyLoadListeners() {
    document.removeEventListener('scroll', lazyload);
    window.removeEventListener('resize', lazyload);
    window.removeEventListener('orientationChange', lazyload);
  }
})();

},{}],8:[function(require,module,exports){
'use strict';

$('input[type="tel"]').each(function initMask() {

  var $input = $(this);
  var defaultPlaceholder = $input.attr('placeholder') || '';
  var defaultPlaceholderMask = '+7 (___) ___-__-__';
  var phoneMask = '+7 (000) 000-00-00';
  var pasted = false;

  $input.on('focus', function () {
    $input.attr('placeholder', defaultPlaceholderMask);
  }).on('blur', function () {
    $input.attr('placeholder', defaultPlaceholder);
  });

  $input.on('paste drop', function () {
    var isEmpty = !$input.val();
    var element = $input.get(0);
    var selectionLength = element.selectionEnd - element.selectionStart;

    if (!isEmpty && $input.val().length !== selectionLength) {
      return;
    }

    pasted = true;
  });

  $input.on('input', function (e) {
    var value = $input.val();

    if (value.startsWith('8')) {
      if (pasted && value.length !== 11) {
        pasted = false;
        return;
      }

      pasted = false;

      e.stopPropagation();

      value = value.replace('8', '+7');
      $input.val(value);
    }
  });

  $input.mask(phoneMask);
});

},{}],9:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var $menus = $('.js-menu');

if (!_globals.IS_TOUCH_DEVICE) {
  $menus.on('mouseenter.js-menu', 'li', function mouseenterHandler() {
    var $this = $(this);

    clearTimeout($this.data('unhoverTimeout'));

    if ($this.hasClass('has-dropdown')) {
      $this.data('hoverTimeout', setTimeout(function () {
        $this.addClass('is-hovered');
      }, 100));
    } else {
      // $this.addClass('is-hovered');
    }
  });

  $menus.on('mouseleave.js-menu', 'li', function mouseleaveHandler() {
    var $this = $(this);

    clearTimeout($this.data('hoverTimeout'));

    $this.data('unhoverTimeout', setTimeout(function () {
      $this.removeClass('is-hovered');
    }, 200));
  });
}

if (_globals.IS_TOUCH_DEVICE) {
  $menus.on('click.js-m-menu', 'a', function clickHandler(e) {
    e.preventDefault();

    var $anchor = $(this);
    var $parent = $anchor.parent();
    var hasDropdown = $parent.hasClass('has-dropdown');
    var isHovered = $parent.hasClass('is-hovered');
    var link = $anchor.attr('href');

    $parent.siblings().removeClass('is-hovered');

    if (!hasDropdown) {
      window.location.href = $anchor.attr('href');
    } else if (isHovered && link) {
      window.location.href = link;
    } else {
      $parent.toggleClass('is-hovered');
    }
  });
}

$('.js-toggle-mobile-menu').click(function (e) {
  e.preventDefault();

  var openedClass = 'menu-is-opened';

  _globals.$BODY.toggleClass(openedClass);

  // if ($BODY.hasClass(openedClass)) {
  //   $BODY.on('click.close-menu', (ev) => {
  //     const $target = $(ev.target);

  //     if ($target.closest('.header__menu').length || $target.hasClass('js-toggle-mobile-menu')) {
  //       ev.stopPropagation();
  //       return;
  //     }

  //     $BODY.removeClass(openedClass).off('click.close-menu');
  //   });
  // }
  // else {
  //   $BODY.removeClass(openedClass).off('click.close-menu');
  // }
});

(function () {

  $('.js-toggle-language').click(function toggleLanguageWidget(e) {
    e.preventDefault();
    var $this = $(this);
    var $widget = $this.closest('.js-language-widget');
    var openedClass = 'is-expanded';

    $widget.toggleClass(openedClass);

    if ($widget.hasClass(openedClass)) {
      _globals.$BODY.on('click.close-language-widget', function (ev) {
        var $target = $(ev.target);

        if ($target.closest('.js-language-widget').length) return;

        $widget.removeClass(openedClass);
        removeHandler();
      });
    } else {
      removeHandler();
    }
  });

  function removeHandler() {
    _globals.$BODY.off('click.close-language-widget');
  }
})();

(function () {

  if (!$('.popup-menu').length) return;

  var openedClass = 'menu-is-opened';

  $('.js-toggle-popup-menu').click(function (e) {
    e.preventDefault();

    _globals.$BODY.toggleClass(openedClass);

    $('.popup-menu .is-hovered').removeClass('is-hovered');

    if (_globals.$BODY.hasClass(openedClass)) {
      var url = new URL(window.location);
      url.searchParams.set('menu', 'opened');
      window.history.pushState({}, '', url);

      _globals.$BODY.on('click.close-menu', function (ev) {
        var $target = $(ev.target);

        if ($target.closest('.popup-menu').length || $target.hasClass('js-toggle-popup-menu')) {
          return;
        }

        closeMenu(true);
      });
    } else {
      closeMenu(true);
    }
  });

  function closeMenu(back) {
    _globals.$BODY.removeClass(openedClass).off('click.close-menu');

    var url = new URL(window.location);
    if (url.searchParams.get('menu') && back) {
      window.history.back();
    }
  }

  window.addEventListener('popstate', onPopstate);

  function onPopstate() {
    if (_globals.$BODY.hasClass(openedClass)) {
      closeMenu(false);
    }
  }

  $('.js-toggle-mobile-menu-dropdown').click(function togglerClickHandler() {
    $(this).closest('ul').toggleClass('is-expanded');
  });

  // const hammerobject = new Hammer($('.popup-menu')[0], {});
  // hammerobject.on('swipeleft', function(e) {
  //   if ($BODY.hasClass(openedClass)) {
  //     closeMenu(true);
  //   }
  // });
})();

},{"./globals.js":5}],10:[function(require,module,exports){
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; /* global initCardApp */

var _globals = require('./globals.js');

var _helpers = require('./helpers.js');

var OVERLAY_LOADER_DEFAULTS = {
  hideSelector: '.js-close-popup',
  fixedElements: '.js-fixed-element',
  onShow: function onShow() {
    _globals.$DOCUMENT.trigger('popupShown');
  }
};

_globals.$DOCUMENT.on('click.overlay-open', '.js-overlay', function clickHandler(e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  if ($(this).hasClass('js-change-overlay-title')) {
    $($popup).find('.product-popup__title').text($(this).text());
  }

  $.overlayLoader(true, _extends({
    node: $popup
  }, OVERLAY_LOADER_DEFAULTS));
});

_globals.$DOCUMENT.on('click.map-overlay-open', '.js-map-content-overlay', function clickHandler(e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, _extends({
    node: $popup
  }, OVERLAY_LOADER_DEFAULTS, {
    onShow: function onShow() {
      _globals.$DOCUMENT.trigger('initMapTabSliders');
    }
  }));
});

_globals.$DOCUMENT.on('click.js-filter-toggler', '.js-filter-toggler', function (e) {
  e.preventDefault();

  var openedClass = 'filter-is-opened';

  _globals.$BODY.addClass(openedClass);
});

_globals.$DOCUMENT.on('click.js-toggle-mobile-filters', '.js-toggle-mobile-filters', function (e) {
  e.preventDefault();

  var openedClass = 'filter-is-opened';

  _globals.$BODY.removeClass(openedClass);
});

_globals.$DOCUMENT.on('click.overlay-open', '.js-ajax-overlay', function clickHandler(e) {
  e.preventDefault();

  var $self = $(this);
  var $node = $($self.attr('href'));

  $.overlayLoader(true, _extends({
    node: $node
  }, OVERLAY_LOADER_DEFAULTS));

  $.ajax({
    type: $self.data('type'),
    url: $self.data('ajax-url'),
    data: parseAjaxOverlayParams($self.data('ajax-params')),
    timeout: 10000,
    success: function success(data) {
      $node.find('.js-ajax-data').replaceWith(data);
      initCardApp('#' + $node.find('.js-card-app').attr('id'));
      setTimeout(function () {
        $(document).trigger('ajaxPopupLoaded');
      }, 350);
    },
    error: function error() {}
  });
});

function parseAjaxOverlayParams(str) {
  if (str === undefined || str === '') return {};

  if ((typeof str === 'undefined' ? 'undefined' : _typeof(str)) === 'object') return str;

  var jsonParams = JSON.parse(str);
  if (jsonParams) return jsonParams;

  var obj = {};
  var array = str.split('&');

  array.forEach(function (value) {
    var a = value.split('=');
    /* eslint-disable prefer-destructuring */
    obj[a[0]] = a[1];
    /* eslint-enable prefer-destructuring */
    return true;
  });

  return obj;
}

// Autofocus
_globals.$DOCUMENT.on('overlayLoaderShow', function (e, $node) {
  $node.find('.js-autofocus-inp').focus();
});

// Header search form
_globals.$DOCUMENT.on('click.js-open-header-search', '.js-open-header-search', function clickHandler(e) {
  var $this = $(this);

  if (_globals.SMALL_MOBILE_WIDTH >= _helpers.WINDOW_WIDTH) {
    if (!$this.parent().hasClass('is-opened')) {
      e.preventDefault();
      $this.parent().addClass('is-opened');
    }
  }
});

},{"./globals.js":5,"./helpers.js":6}],11:[function(require,module,exports){
'use strict';

/* global THREE */

$(function () {

  var $container = $('.js-product-model');
  if (!$container.length) return;

  var $progress = $('.js-product-model-progress');
  var scene = new THREE.Scene();
  var camera = new THREE.PerspectiveCamera(50, $container.width() / $container.height(), 1, 1000);
  var renderer = new THREE.WebGLRenderer({
    alpha: true,
    gammaOutput: true,
    physicallyCorrectLights: true,
    antialias: true
  });
  var source = $container.data('src');
  renderer.setSize($container.width(), $container.height());
  $container.append(renderer.domElement);

  $(window).resize(function () {
    setTimeout(function () {
      renderer.setSize($container.width(), $container.height());
    }, 500);
  });

  var controls = new THREE.OrbitControls(camera, renderer.domElement);
  controls.autoRotate = true;
  controls.enableZoom = false;

  var loader = new THREE.GLTFLoader();

  var dracoLoader = new THREE.DRACOLoader();
  dracoLoader.setDecoderPath('/js/libs/draco/');
  loader.setDRACOLoader(dracoLoader);

  loader.load(source, function (obj) {
    $container.addClass('is-ready');
    $progress.hide();

    obj.scene.scale.set(5, 5, 5);

    fitCameraToObject(obj, 1.05, controls);

    scene.add(obj.scene);
  },
  // onProgress callback
  function (xhr) {
    $progress.text(parseInt(xhr.loaded / xhr.total * 100, 10) + '%');
  }, function () {
    // eslint-disable-next-line no-console
    console.error('An error happened');
  });

  var dirLight1 = new THREE.DirectionalLight(0xffffff);
  dirLight1.position.set(1, 1, 2);
  scene.add(dirLight1);

  var dirLight2 = new THREE.DirectionalLight(0xcccccc);
  dirLight2.position.set(-1, -1, -1);
  scene.add(dirLight2);

  // const ambientLight = new THREE.AmbientLight(0xcccccc);
  // scene.add(ambientLight);

  function animate() {
    requestAnimationFrame(animate);
    // scene.rotation.y += 0.01;
    controls.update();
    renderer.render(scene, camera);
  }
  animate();

  function fitCameraToObject(object, offset, orbitControls) {
    var boundingBox = new THREE.Box3();
    boundingBox.setFromObject(object.scene);

    var center = boundingBox.getCenter(new THREE.Vector3());
    var size = boundingBox.getSize(new THREE.Vector3());

    // get the max side of the bounding box
    // const maxDim = Math.max(size.x, size.y, size.z);
    var maxDim = Math.max(size.x, size.y);
    var fov = camera.fov * (Math.PI / 180);
    var cameraZ = Math.abs(maxDim / 4 * Math.tan(fov * 2));

    // offset the camera as desired - usually a value of ~ 1.25 is good to prevent
    // object filling the whole canvas
    if (offset !== undefined && offset !== 0) cameraZ *= offset;

    camera.position.set(center.x, center.y, cameraZ);

    // set the far plane of the camera so that it easily encompasses the whole object
    var minZ = boundingBox.min.z;
    var cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;

    camera.far = cameraToFarEdge * 3;
    // camera.far = cameraToFarEdge * .1;
    camera.updateProjectionMatrix();

    if (orbitControls !== undefined) {
      // set camera to rotate around center of loaded object
      orbitControls.target = center;
      // prevent camera from zooming out far enough to create far plane cutoff
      orbitControls.maxDistance = cameraToFarEdge * 1;
    }
  }
});

},{}],12:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var _helpers = require('./helpers.js');

// Scroll to
// ---------

_globals.$DOCUMENT.on('click.scroll-to', '.js-scroll-to', function clickHandler(e) {
  e.preventDefault();

  var $link = $(this);
  var $elementToScroll = $($link.attr('href'));
  var speed = $link.data('speed') || 150;
  var offset = $link.data('offset') || -$('.header').height();

  _globals.$WINDOW.scrollTo($elementToScroll, { duration: speed, offset: offset });
});

// Scrolling to top
// ----------------

if (!(0, _helpers.IS_MOBILE_WIDTH)()) {
  var $goTopButton = $('<div class="go-top-btn"><span></span><span></span></div>');

  $goTopButton.click(function () {
    _globals.$WINDOW.scrollTo(0, 200);
  });

  _globals.$WINDOW.scroll(function () {
    var scrollTop = _globals.$WINDOW.scrollTop();
    if (scrollTop > 0) {
      $goTopButton.addClass('is-visible');
    } else {
      $goTopButton.removeClass('is-visible');
    }
  });

  _globals.$BODY.append($goTopButton);
}

},{"./globals.js":5,"./helpers.js":6}],13:[function(require,module,exports){
'use strict';

var $inp = $('.js-city-search');

$inp.keyup(function () {
  var $inpValue = $inp.val();
  var $items = $('.js-search-body a');

  $items.each(function citySearch(index, value) {
    if (!value.innerHTML.toLowerCase().includes($inpValue.toLowerCase())) {
      // !== $inpValue
      $(this).fadeOut();
    } else {
      $(this).fadeIn();
    }
  });
});

},{}],14:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

$('.js-slideshow').each(function createSlider() {
  var $this = $(this);
  var $slider = $(this);
  var $status = $('.js-completed-slider-status');

  $slider.on('init reInit beforeChange', function (event, slick, currentSlide, nextSlide) {
    var i = (nextSlide || 0) + 1;
    if (slick.slideCount < 10) {
      $status.html('0' + i + '/0' + slick.slideCount);
    } else {
      $status.html(i + '/' + slick.slideCount);
    }
  });

  $this.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 5000,
    dots: false,
    arrows: true,
    pauseOnHover: false,
    responsive: [{
      breakpoint: 623 + 1,
      settings: {
        dots: true,
        arrows: false
      }
    }]
  });
});

$('.js-product-slider').each(function createSlider() {
  var $this = $(this);
  var $connected = $this.closest('[data-slider-root]').find('.js-product-thumbs');

  $this.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 5000,
    dots: true,
    arrows: false,
    pauseOnHover: true,
    vertical: true,
    verticalSwiping: true,
    touchThreshold: 100,
    asNavFor: $connected,
    responsive: [{
      breakpoint: 1024,
      settings: {
        vertical: false,
        verticalSwiping: false
      }
    }]
  });
});

$('.js-product-thumbs').each(function createSlider() {
  var $this = $(this);
  var $connected = $this.closest('[data-slider-root]').find('.js-product-slider');

  if ($this.hasClass('slick-initialized')) return;

  $this.slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    dots: false,
    asNavFor: $connected,
    focusOnSelect: true,
    waitForAnimate: false,
    vertical: true,
    responsive: [{
      breakpoint: 1024,
      settings: {}
    }]
  });
});

$('.js-how-slideshow').each(function createSlider() {
  var $this = $(this);

  $this.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 5000,
    dots: false,
    arrows: true,
    pauseOnHover: false,
    responsive: [{
      breakpoint: 623 + 1,
      settings: {
        arrows: false,
        dots: true
      }
    }]
  });
});

$('body').on('initVitrineSliders iasPageRendered', function () {
  $('.js-vitrine-item-slider:not(.inited)').each(function createSlider() {
    var $this = $(this);

    $this.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      autoplay: false,
      autoplaySpeed: 5000,
      dots: true,
      arrows: false,
      pauseOnHover: false
    });

    $this.addClass('inited');
  });
}).trigger('initVitrineSliders');

$('.js-history-slideshow').each(function createSlider() {
  var $this = $(this);

  $this.slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 5000,
    dots: false,
    arrows: true,
    pauseOnHover: false,
    adaptiveHeight: true,
    // leftMode: true,
    responsive: [{
      breakpoint: 768 + 1,
      settings: {
        arrows: false,
        slidesToShow: 2
      }
    }, {
      breakpoint: 623 + 1,
      settings: {
        slidesToShow: 1
      }
    }]
  });
});

$('.js-news-slideshow').each(function createSlider() {
  var $this = $(this);

  $this.slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 5000,
    dots: false,
    arrows: true,
    pauseOnHover: false,
    responsive: [{
      breakpoint: 1024 + 1,
      settings: {
        slidesToShow: 2,
        arrows: true
      }
    }, {
      breakpoint: 495 + 1,
      settings: {
        slidesToShow: 1.25,
        arrows: false
      }
    }]
  });
});

$('.js-project-slideshow').each(function createSlider() {
  var $this = $(this);

  $this.slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 5000,
    dots: false,
    arrows: true,
    pauseOnHover: false,
    responsive: [{
      breakpoint: 1024 + 1,
      settings: {
        slidesToShow: 2,
        arrows: true
      }
    }, {
      breakpoint: 495 + 1,
      settings: {
        slidesToShow: 1,
        arrows: true
      }
    }]
  });
});

$('.js-vitrine-slider').each(function createSlider() {
  var $this = $(this);
  var $parent = $this.parent();

  $this.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    appendArrows: $parent,
    responsive: [{
      breakpoint: _globals.TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 4
      }
    }, {
      breakpoint: _globals.PORTRAIT_TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1
      }
    }]
  });
});

$('.js-gallery-slider').each(function createSlider() {
  var $this = $(this);
  var $parent = $this.parent();

  $this.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    appendArrows: $parent,
    responsive: [{
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 4
      }
    }]
  });
});

$('.js-project-slider').each(function createSlider() {
  var $this = $(this);
  var $connected = $this.closest('[data-slider-root]').find('.js-project-thumbs');

  $this.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    dots: true,
    asNavFor: $connected,
    adaptiveHeight: false,
    responsive: [{
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        adaptiveHeight: true
      }
    }]
  });
});

$('.js-project-thumbs').each(function createSlider() {
  var $this = $(this);
  var $connected = $this.closest('[data-slider-root]').find('.js-project-slider');

  if ($this.hasClass('slick-initialized')) return;

  $this.slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    dots: false,
    asNavFor: $connected,
    focusOnSelect: true,
    waitForAnimate: false
  });
});

},{"./globals.js":5}],15:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

_globals.$DOCUMENT.on('mousedown.js-spinner', '.js-spinner-down, .js-spinner-up', function mousedownHandler() {
  var $spinnerControl = $(this);
  var $input = $spinnerControl.siblings('input');
  var step = $input.data('step') ? $input.data('step') : 1;
  var mayBeZero = $input.data('zero') ? $input.data('zero') : false;
  var value = parseInt($input.val(), 10);
  var incTimeout = void 0;
  var incInterval = void 0;
  var decTimeout = void 0;
  var decInterval = void 0;

  $spinnerControl.on('mouseup.js-spinner', clearAll).on('mouseleave.js-spinner', $spinnerControl, clearAll);

  if ($spinnerControl.hasClass('js-spinner-down')) {
    decVal();decTimeout = setTimeout(function () {
      decInterval = setInterval(decVal, 70);
    }, 300);
  }

  if ($spinnerControl.hasClass('js-spinner-up')) {
    incVal();incTimeout = setTimeout(function () {
      incInterval = setInterval(incVal, 70);
    }, 300);
  }

  function incVal() {
    if ($.isMouseLoaderActive()) return;

    value += step;
    $input.val(value).change();
  }

  function decVal() {
    if ($.isMouseLoaderActive()) return;

    if (mayBeZero) {
      if (value >= step) {
        value -= step;
        $input.val(value).change();
      }
    } else if (value > step) {
      value -= step;
      $input.val(value).change();
    }
  }

  function clearAll() {
    clearTimeout(decTimeout);clearInterval(decInterval);
    clearTimeout(incTimeout);clearInterval(incInterval);
  }
});

_globals.$DOCUMENT.on('keydown', '.js-spinner input', function (e) {
  if (isKeyToSkip(e.keyCode, e.ctrlKey)) {
    return e.keyCode;
  }
  if (isNotADigitKey(e.keyCode)) {
    e.preventDefault();
  }
  return e.keyCode;
});

_globals.$DOCUMENT.on('keyup paste', '.js-spinner input', function keyupHandler() {
  var $input = $(this);
  var mayBeZero = $input.data('zero') ? $input.data('zero') : false;

  if ($input.val() === 0 || $input.val() === '') {
    if (!mayBeZero) {
      $input.val(1);
    } else {
      $input.val(0);
    }
  }
});

function isKeyToSkip(keyCode, ctrlKey) {
  return isDelKey(keyCode) || isBackspaceKey(keyCode) || isTabKey(keyCode) || isEscKey(keyCode) || isSelectAllHotkeys(keyCode, ctrlKey) || isPositioningKey(keyCode);
}

function isDelKey(keyCode) {
  return keyCode === 46;
}

function isBackspaceKey(keyCode) {
  return keyCode === 8;
}

function isTabKey(keyCode) {
  return keyCode === 9;
}

function isEscKey(keyCode) {
  return keyCode === 27;
}

function isSelectAllHotkeys(keyCode, ctrlKey) {
  // Ctrl+A
  return keyCode === 65 && ctrlKey === true;
}

function isPositioningKey(keyCode) {
  // arrows or home / end
  return keyCode >= 35 && keyCode <= 39;
}

function isNotADigitKey(keyCode) {
  // including numpad keys
  return (keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105);
}

},{"./globals.js":5}],16:[function(require,module,exports){
'use strict';

var _helpers = require('./helpers.js');

$(function () {

  $('.js-spoiler').each(function spoilerHandler() {
    var $this = $(this);
    $(window).on('spoilersInit resize', function () {
      var isExpanded = $this.hasClass('is-expanded');
      var isInsideHiddenTab = $this.closest('.tabs-panel:not(.is-active)').length;
      var isInsideCollapsedSpoiler = $this.closest('.js-spoiler:not(.is-expanded)').length;
      // const isInsideFilter = $this.closest('#filter-aside').length;
      var $el = $this.find('.js-spoiler-body').first();
      var el = $el[0];
      $('.js-spoiler-body').each(function addTempState() {
        $(this)[0].style.setProperty('transition', 'none');
        $(this).find('ul').addClass('is-expanded-for-calc');
      });
      if (!isExpanded) $this.addClass('is-expanded-for-calc');
      if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').addClass('is-expanded');
      if (isInsideHiddenTab) $this.closest('.tabs-panel').addClass('is-active');
      // if (isInsideFilter) $this.closest('.catalogue-page__aside')[0].style.setProperty('display', 'block');
      el.style.setProperty('--max-height', '');
      el.style.setProperty('--max-height', $el.outerHeight() + 'px');
      if (!isExpanded) $this.removeClass('is-expanded-for-calc');
      if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').removeClass('is-expanded');
      if (isInsideHiddenTab) $this.closest('.tabs-panel').removeClass('is-active');
      // if (isInsideFilter) $this.closest('.catalogue-page__aside')[0].style.setProperty('display', '');
      $('.js-spoiler-body').each(function removeTempState() {
        $(this)[0].style.setProperty('transition', '');
        $(this).find('ul').removeClass('is-expanded-for-calc');
      });
    });

    if ($this.hasClass('js-save-spoiler-state')) {
      if (localStorage.getItem('SPOILER_STATE_' + $this.attr('id')) === 'true') {
        $this.addClass('is-expanded');
      }
    }
  });
  setTimeout(function () {
    $(window).trigger('spoilersInit');
  }, 0);

  $(document).on('click', '.js-toggle-spoiler', function toggleClickHandler() {
    var $this = $(this);
    var $spoiler = $this.closest('.js-spoiler');
    var scrollAfter = $this.hasClass('js-scroll-to-closed-spoiler');
    var saveState = $spoiler.hasClass('js-save-spoiler-state');
    $spoiler.toggleClass('is-expanded');
    if (scrollAfter && !$spoiler.hasClass('is-expanded')) {
      $(window).scrollTo($spoiler, { duration: 200, offset: -180 });
    }
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
    if (saveState) {
      localStorage.setItem('SPOILER_STATE_' + $spoiler.attr('id'), $spoiler.hasClass('is-expanded'));
    }
  });

  $('.js-toggle-mobile-spoiler').click(function mobileToggleClickHandler() {
    if (!(0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) return;
    $(this).closest('.js-spoiler').toggleClass('is-expanded');
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
  });

  $('.js-toggle-vitrine-more').click(function spoilerHandler(e) {
    e.preventDefault();
    var $this = $(this);
    var $spoiler = $this.closest('.js-spoiler');
    $spoiler.toggleClass('is-expanded');
  });

  $('.js-text-toggler').click(function spoilerHandler(e) {
    e.preventDefault();
    var $container = $(this).parent();
    $container.toggleClass('is-expanded');
  });
});

},{"./helpers.js":6}],17:[function(require,module,exports){
'use strict';

$('.js-tabs .tabs-nav li a').click(function clickHandler(e) {
  e.preventDefault();

  var $this = $(this);
  var $panel = $($this.attr('href'));

  $this.closest('li').addClass('is-active').siblings().removeClass('is-active');
  $panel.closest('.tabs').find('.tabs-panel').removeClass('is-active').hide();
  $panel.fadeIn().addClass('is-active');
});

$(function () {
  $('.js-tabs').each(function handler() {
    $(this).find('.tabs-nav li:first a').click();
  });
});

$('.js-toggle-map-view').click(function handler(e) {
  e.preventDefault();

  var $this = $(this);

  $this.addClass('is-active').siblings().removeClass('is-active');
  $($this.attr('href')).stop(true, true).fadeIn();
  $($this.siblings().attr('href')).stop(true, true).hide();

  if ($this.attr('href') === '#map-view') {
    $('.map-section__mobile-content-link').show();
  } else {
    $('.map-section__mobile-content-link').hide();
  }
});

$('.js-adv-tabs').on('mouseenter.js-adv-tabs', '.adv-tabs__item', function mouseenterHandler() {
  $(this).addClass('is-hovered');
});

$('.js-adv-tabs').on('mouseleave.js-adv-tabs', '.adv-tabs__item', function mouseleaveHandler() {
  $(this).removeClass('is-hovered');
});

$('.js-homepage-controls li').click(function HomepageControls(e) {
  e.preventDefault();
  var $this = $(this);
  var $msg = $('.js-homepage-panel div');

  $this.addClass('active').siblings().removeClass('active');
  // $this.data('settings');

  $msg.stop(true, true).hide();
  $('#' + $this.data('settings')).stop(true, true).fadeIn();
});

},{}],18:[function(require,module,exports){
'use strict';

$(function () {
  $('.js-play-video').click(function clickHandler(event) {
    event.preventDefault();
    var $this = $(this);
    var $videoLink = $this.prop('href');
    var $video = $('<iframe width="100%" height="100%" class="info-block__img"\n    src="' + $videoLink + '" frameborder="0" allowfullscreen></iframe>');
    $this.parent().addClass('with--video');
    $this.replaceWith($video);
  });
});

},{}],19:[function(require,module,exports){
'use strict';

var _PriceComponent = require('./components/PriceComponent.js');

var _PriceComponent2 = _interopRequireDefault(_PriceComponent);

var _OffersListRadioComponent = require('./components/OffersListRadioComponent.js');

var _OffersListRadioComponent2 = _interopRequireDefault(_OffersListRadioComponent);

var _OffersListSelectComponent = require('./components/OffersListSelectComponent.js');

var _OffersListSelectComponent2 = _interopRequireDefault(_OffersListSelectComponent);

var _OffersParamComponent = require('./components/OffersParamComponent.js');

var _OffersParamComponent2 = _interopRequireDefault(_OffersParamComponent);

var _OffersImageComponent = require('./components/OffersImageComponent.js');

var _OffersImageComponent2 = _interopRequireDefault(_OffersImageComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

window.initCardApp = function () {
  if (!$('#js-card-app').length) return false;

  var cardPriceApp = new Vue({
    el: '#js-card-app',
    components: {
      'price-component': _PriceComponent2.default,
      'offers-list-radio': _OffersListRadioComponent2.default,
      'offers-list-select': _OffersListSelectComponent2.default,
      'offers-param': _OffersParamComponent2.default,
      'offers-image': _OffersImageComponent2.default
    },
    data: function data() {
      return {
        offerInner: {},
        targetButton: ''
      };
    },

    computed: {
      offer: {
        get: function get() {
          return this.offerInner;
        },
        set: function set(offerData) {
          this.offerInner = offerData;
          this.$emit('changeOffer', this.offerInner);
          this.updateBasketButton();
        }
      }
    },
    mounted: function mounted() {
      this.$nextTick(function nextTickHandler() {
        this.offer = JSON.parse(this.$el.getAttribute('data-default-offer'));
        this.targetButton = this.$el.getAttribute('data-target-button');
        this.$emit('setDefaultOffer');
      });
    },

    methods: {
      updateBasketButton: function updateBasketButton() {
        // Обновляем данные на кнопке купить
        var data = $(this.targetButton).data();
        $.extend(data, this.offerInner.basketData);
        $(this.targetButton).data(data);
      }
    }
  });

  return cardPriceApp;
}; /* global Vue */

/**
 * Подключение в common.js
 *   import './modules/card-choose-offer.js';
 *
 * Пример подключения в шаблоне
 * <div id="js-card-app" class="product-card__controls-column"
 *   data-default-offer='<?=$model->getBasketObjectJson()?>' data-target-button=".js-cart-basket-button">

 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="{'is-checked': isActive(offer.id)}" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="$root.offer = offer">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 *
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted">
 *     <?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?>
 *   </div>
 * </div>
 * </price-component>
 *
 * </div>
 *
 */


window.initCardApp();

},{"./components/OffersImageComponent.js":20,"./components/OffersListRadioComponent.js":21,"./components/OffersListSelectComponent.js":22,"./components/OffersParamComponent.js":23,"./components/PriceComponent.js":24}],20:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var OffersImage = {
  methods: {
    initImage: function initImage() {
      // console.log('Нужно обновить картинку!');
    },
    getMainImage: function getMainImage() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'big';

      if (!Object.prototype.hasOwnProperty.call(this.$root.offer, 'images')) return null;

      if (!Object.keys(this.$root.offer.images).length) return null;

      if (!Object.prototype.hasOwnProperty.call(this.$root.offer.images, 'main')) return null;

      if (!Object.keys(this.$root.offer.images.main).length) return null;

      return this.$root.offer.images.main[0][type].src;
    },
    getMainImageWidth: function getMainImageWidth() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'big';

      if (!this.getMainImage(type)) return null;

      return this.$root.offer.images.main[0][type].width;
    },
    getMainImageHeight: function getMainImageHeight() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'big';

      if (!this.getMainImage(type)) return null;

      return this.$root.offer.images.main[0][type].height;
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$root.$on('changeOffer', function () {
      _this.initImage();
    });
  }
};

exports.default = OffersImage;

},{}],21:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пример подключения в шаблоне:
 * <?php $offers = $model->getOffersWithParameter(false) ?>
 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="getClass(offer)" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="click(offer)">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 */

/**
 * @type {{props: {offersList: {type: ArrayConstructor}}, methods: {isActive(*): *}}}
 */
var OffersListRadio = {
  props: {
    offersList: {
      type: Array
    },
    selectedClass: {
      type: String,
      default: 'is-checked'
    },
    disabledClass: {
      type: String,
      default: 'disabled'
    }
  },
  methods: {
    isActive: function isActive(id) {
      return this.$root.offer.id === id;
    },
    click: function click(offer) {
      if (offer.disabled) return;

      this.$root.offer = offer;
    },
    getClass: function getClass(offer) {
      var classArray = [];

      if (this.isActive(offer.id)) {
        classArray.push(this.selectedClass);
      }

      if (offer.disabled) {
        classArray.push(this.disabledClass);
      }

      return classArray.join(' ');
    }
  }
};

exports.default = OffersListRadio;

},{}],22:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @type {{props: {offersList: {type: ArrayConstructor}}}}
 */
var OffersListSelect = {
  data: function data() {
    return {
      selectedId: null
    };
  },

  props: {
    offersList: {
      type: Array
    }
  },
  methods: {
    setOfferById: function setOfferById(id) {
      var _this = this;

      Object.keys(this.offersList).forEach(function (i) {
        if (_this.offersList[i].id === id) {
          _this.$root.offer = _this.offersList[i];
          return false;
        }
        return true;
      });
    },
    initSelectric: function initSelectric() {
      $(this.$el).find('select.no-default-selectric').selectric({
        disableOnMobile: false,
        nativeOnMobile: true
      }).on('change', function changeHandler() {
        $(this).trigger('input');
      });
    }
  },
  watch: {
    selectedId: function selectedId(offerId) {
      this.setOfferById(offerId);
    }
  },

  mounted: function mounted() {
    var _this2 = this;

    // Устанавливаем значение по умолчанию
    this.$root.$on('setDefaultOffer', function () {
      _this2.selectedId = _this2.$root.offer.id;
      // this.initSelectric();
    });

    this.$nextTick(function () {
      // this.initSelectric();
    });
  }
};

exports.default = OffersListSelect;

},{}],23:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var OffersParam = {
  data: function data() {
    return {
      param: {}
    };
  },

  methods: {
    initParams: function initParams(offer) {
      var outParam = {};

      Object.keys(offer.parameters).forEach(function (i) {
        var param = offer.parameters[i];
        if (param.keys) {
          Object.keys(param.keys).forEach(function (j) {
            outParam[j] = {
              id: param.id,
              name: param.name,
              value: param.value
            };
          });
        } else {
          outParam[param.id] = {
            id: param.id,
            name: param.name,
            value: param.value
          };
        }
      });

      this.param = outParam;
      // this.$root.$data.param = outParam;
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$root.$on('changeOffer', function (offer) {
      _this.initParams(offer);
    });
  },


  template: '<div></div>'
};

exports.default = OffersParam;

},{}],24:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пример подключения в шаблоне
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted">
 *     <?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?>
 *   </div>
 * </div>
 * </price-component>
 */

/* eslint-disable */
/**
 * @type {{props: {price: {type: NumberConstructor}, oldPrice: {type: NumberConstructor, default: number}, currencySuffix: {type: StringConstructor, default: string}, emptyPriceText: {type: StringConstructor, default: string}}, computed: {economyPercent(): (number|*), priceFormatted(): *, oldPriceFormatted(): *, economyPercentFormatted(): string}}}
 */
/* eslint-enable */
var Price = {
  props: {
    price: {
      type: Number
    },
    oldPrice: {
      type: Number,
      default: 0
    },
    currencySuffix: {
      type: String,
      default: ' <span class="currency"><span>₽</span></span>'
    },
    emptyPriceText: {
      type: String,
      default: ' <span class="call">Спец. цена, звоните!</span>'
    }
  },
  computed: {
    economyPercent: function economyPercent() {
      if (!this.oldPrice) return 0;
      var percent = (this.price - this.oldPrice) * 100 / this.oldPrice;
      return Math.ceil(percent);
    },
    priceFormatted: function priceFormatted() {
      if (this.price > 0) return this.price.toLocaleString() + this.currencySuffix;
      return this.emptyPriceText;
    },
    oldPriceFormatted: function oldPriceFormatted() {
      if (this.oldPrice > 0) return this.oldPrice.toLocaleString() + this.currencySuffix;
      return this.emptyPriceText;
    },
    economyPercentFormatted: function economyPercentFormatted() {
      return this.economyPercent + '%';
    }
  }
};

exports.default = Price;

},{}],25:[function(require,module,exports){
'use strict';

/* global Vue */

// import Price from './components/PriceComponent.js';

window.initOrderApp = function () {
  if (!$('#js-order-app').length) return false;

  var orderApp = new Vue({
    el: '#js-order-app',
    components: {
      // 'price-component': Price,
    },
    data: function data() {
      return {
        message: 'test'
      };
    }
  });

  return orderApp;
};

window.initOrderApp();

},{}]},{},[2]);
